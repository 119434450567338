
/*
*--------------------------------------------------------------------------------
*-------------------------------------- VARIABLES ----------------------------------
*--------------------------------------------------------------------------------
*/
$login-cnt-bg: rgb(147, 205, 252);
$login-cnt-gradiant-bg: linear-gradient(90deg, rgba(147, 205, 252, 1) 0%, rgba(218, 227, 247, 1) 100%);

body {
  margin:0px;
}

body:has(.loginAnimation){
  overflow: hidden;
}


#mainContent {
  margin-top: 3%;
  margin-left: 2%;
  margin-right: 2%;
}

.user-profile {
  right: auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -21px;
}

.user-profile-card {
  border-radius: 16px !important;
}

.user-empty-card {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  flex-direction: "column";
}

.user-profile-about {
  position: relative;
  margin-left: 5;
  top: -5;
}

.user-tabs {
  float: right;
  position: relative;
  bottom: 53px;
  margin-bottom: -61px;
  max-width: 250px;
}

.user-image-card {
  overflow: hidden;
  position: relative;
  border-radius: 16px !important;
  z-index: 0;
  padding: 24px;
}

.user-friend-list {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  border-radius: 16px;
  z-index: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.user-icon-and-text {
  display: "flex";
  align-items: "center";
  flex-wrap: "wrap";
}

#logo {
  filter: brightness(0%) contrast(100%);
}

#logoLight {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(185deg) brightness(200%) contrast(101%);
}

/*
*--------------------------------------------------------------------------------
*-------------------------------------- EVENTS ----------------------------------
*--------------------------------------------------------------------------------
*/

.user-background-image-event {
  width: 30%;
  height: 40vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0px 0px;
}

.MuiAvatar-root {
  border: 2px solid #fff;
  box-sizing: content-box;
  margin-left: -8px;
  height: 20x;
}


/*
*--------------------------------------------------------------------------------
*-------------------------------------- MAP ----------------------------------
*--------------------------------------------------------------------------------
*/


div[style*="width: 50px; height: 50px; overflow: hidden; position: absolute;"] {
  border-radius: 50% !important;
  border: 2px solid goldenrod;
}


.cluster {
  font-size: 25px;
  background-color: #4ac44a;
  border-radius: 50%;
  overflow: hidden;
}



/*
*--------------------------------------------------------------------------------
*-------------------------------------- CHAT ----------------------------------
*--------------------------------------------------------------------------------
*/

.chat {
  width: 100%;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  margin-bottom: 25px;
}

.message-box_right {
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  background-color: #C8FACD;
  direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.message-box_left {
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  background-color: #cdd6dc;
  direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.chat-container {
  margin-top: 50px;
}

.chat-profile {
  background: #202124;
  color: white;
  border-top-left-radius: 12px;
  padding-bottom: 30px;
}

.message-area_header {
  background: #202124;
  border-top-right-radius: 12px;
  color: white;
}

.message-container {
  height: 69vh;
  overflow: auto;
  box-sizing: border-box;
}

.dspOpen {
  display: none;
}

// scroll --------------------

.messages {
  height: 69vh;
  overflow: auto;
  box-sizing: border-box;
}

.messages::-webkit-scrollbar {
  background-color: #eee;
  height: 6px;
  width: 6px;
  top: 99999999999;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 10px;
}


/*
*--------------------------------------------------------------------------------
*-------------------------------------- LOGIN FORM ------------------------------
*--------------------------------------------------------------------------------
*/


#login-logo {
  filter: brightness(0%) contrast(100%) !important;
  // width: 150% !important;
  width: 100%;
}


#form-checkbox {
  color: black !important;
}

.login-form_btn {
  background-color: #000 !important;
}

.login-form_btn:hover {
  background-color: rgb(39, 36, 36) !important;
}

.login-form_link {
  color: black !important;
  text-decoration: none !important;
}

.login-form_link:hover {
  text-decoration: underline !important;
}

.errorMessage {
  color: green;
}

.loginBackGround {
  background: $login-cnt-bg;
  background: $login-cnt-gradiant-bg;
  padding: 100px;
  border-radius: 15px;
}

.loginAnimation {
  height: 9999px;
  height: 100%;
  width: 100%;
  bottom: 0;
  background-color: black;
  box-shadow: inset 0 0 400px #4d83af;
  position: absolute;
  overflow: hidden;
}

.loginAnimation::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 200%;
  box-shadow: inset 0 0 2000px #93cdfc;
  top: 0%;
  border-radius: 40%;
  animation: wave 12s infinite linear;
}


@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}


.MuiContainer-root {
   width:  60% !important;
   height: 80%;
}

@media screen and (max-width: 750px) {
  .loginAnimation::before {
    height: 116% !important;
    width: 194% !important;
    border-radius: 100% !important;
  }
  .MuiContainer-root {
    width: 85% !important;
  }
}

/*
*--------------------------------------------------------------------------------
*-------------------------------------- FOOTER ----------------------------------
*--------------------------------------------------------------------------------
*/

.footer {
  display: flex;
  // background-color: #000;
  color: black;
  justify-content: center;
  margin-top: auto !important;
  // transition: all 1s ease-in-out;
  border-radius: 10px 10px 0px 0px;
  transform: rotate(360deg);

}
.footer-collapsed {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // transition: all 1s ease-in;
  transform: rotate(270deg);
  height: 82px;
}
/*
*--------------------------------------------------------------------------------
*-------------------------------------- HOME ------------------------------
*--------------------------------------------------------------------------------
*/

.homeChart{
  margin: 0 auto;
  border-radius: 3px;
}

/*
*--------------------------------------------------------------------------------
*-------------------------------------- MEDİA TAG ----------------------------------
*--------------------------------------------------------------------------------
*/

.user-avatar{
  transition: 1s ease-in-out;
}

.user-avatar-name{
  transition: 1s ease-in-out;
}


@media screen and (max-width: 600px) {
  .user-avatar {
    right: auto;
    left: 80px  !important; 
    // bottom: 104px;
    border: 10px solid transparent;
  }
}

@media screen and (max-width: 600px) {
  .user-avatar-name {
    position: relative;
    font-size: 15px;
    word-wrap: break-word ;
    left: -10px !important;
    top: 30px !important;

  }
}



@media screen and (max-width: 900px) {
  .user-avatar {
    right: auto;
    left: 200px ;
    bottom: 104px;
    border: 1px solid transparent;
  }
}

@media screen and (min-width: 901px) {
  .user-avatar {
    right: auto;
    left: 44px;
    bottom: 24px;
    border: 1px solid transparent;
  }
}

@media screen and (max-width: 900px) {
  .user-avatar-name {
    position: relative;
    left: 90px;
    top: 25px;
  }
}



@media screen and (min-width: 901px) {
  .user-avatar-name {
    position: relative;
    left: 54px;
    top: 20px;
  }
}

@media screen and (max-width: 600px) {
  #login-logo {
    width: 100% !important;
  }
  .login-img{
    display:none;
  }
  .MuiBox-root.css-164r41r{
    margin-top: 175px
  }

}


/*
*--------------------------------------------------------------------------------
*-------------------------------------- Reviews ----------------------------------
*--------------------------------------------------------------------------------
*/

.review-image-container {
position: relative;
}
.review-badge {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

}

.review-image {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  grid-column-gap: 10px;
}

.review-no-image {
  border: 1px dashed black;
  width: 100%;
  height: 226px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  

.review-image-description{
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  background: #00000082;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 10px ;
  
}


.popover-container {
  background-color: white;
padding: 10px;
width: 300px;
}

.popover-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 11px;
}

.popover-button {
  width: 10px !important;
  height: 20px;
  padding: 11px;
  font-size: 12px !important;
}




.custom-badge  .MuiBadge-badge {
  // width: 31px;
  // height: 31px;
  margin-right: -20px;
  border-radius: 20px;
  color: #fefff2;
  background-color: #180c0ccf;
}

.flip-card{
  transform: rotateY(180deg);
}

.content {
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card .content {
  transform: rotateY( 180deg ) ;
  transition: transform 1s;
}


.bugsCreate  {
border: none;
border-bottom: 1px solid gray;
font-size: 15px;

}

// #root > div > header{
//   z-index: 0;
// }



/*
*--------------------------------------------------------------------------------
*-------------------------------- CUSTOM MODAL----------------------------------
*--------------------------------------------------------------------------------
*/

/* CUSTOM MODAL */
.custom-modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  overflow: hidden !important;
  background-color: #0f0f0fc4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);


  // box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
}

.custom-modal-close {
  // position: absolute;
  // top: 0;
  // right: 0;
  cursor: pointer;
}

.custom-modal-card {
  /* backdrop-filter: blur(2px); */
  background-color: #161c24bf;
 padding: 13px 0px 17px 22px;
  border: none;
  color: white;
}

.custom-modal-card-flex {
  display: flex;
  flex-direction: column;
}


.custom-modal-inside-of-card {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  position: relative;
}
.MuiDrawer-docked{
  z-index: 0 !important;
}

.header:last(.custom-modal) {
  z-index: 0 !important;
}

// .apexcharts-pie-area {
//   fill: black !important;
// }
// .apexcharts-pie-area:hover {
//   background-color: black !important;
// }

// .apexcharts-legend-marker{
//   color:black !important;
// background-color:black !important
// }


@media (max-width: 960px){
  .img-rel-container {
    width: 80% !important;
  }
}


.img-rel-container > svg {
  margin-left: -22px;
}



/*
*--------------------------------------------------------------------------------
*-------------------------------------- Phenomnen List -------------------------
*--------------------------------------------------------------------------------
*/


.accordion-phenomenon-main{
  margin-left: auto;
  display: flex;
  margin-right: 10px;
  width: auto;
  gap:10px;
}


.accordion-phenomenon-child{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70px,
}


@media (max-width: 700px){
  .accordion-phenomenon-main{
    display: block !important;
  }
}


.spinner-loading {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6.4px solid #474bff;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}

.custom-badge-phenomenonon{
  background: orange;
  font-size: 14px;
  border: 1px solid orange;
  color: white;
  padding: 10px;
  border-radius: 20px;
  text-shadow: 2px 2px black;
}

// @dropzone-ui/react library
.dropzone-ui .dz-ui-header{
  width: calc(100% -  -21px) !important;
}

.transaction{

  display: flex;
  flex-direction: column;
}

.transaction > div  {
  transition: 0.5s ease;
  color: white;
}